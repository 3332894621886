import React from 'react';
import { Link } from 'react-router-dom';
import { useColorModeValue } from '@chakra-ui/react';

export default function Footer() {
  const textColor = useColorModeValue('black', 'white');

  return (
    <footer>
      <ul style={{ color: textColor }}>
        <h5>İletişim</h5>
        <li>Ardahan Ticaret ve Sanayi Odası Karagöl Mah. Kongre Cad. No:67 Kat:4-5 Merkez/ARDAHAN</li>
        <li>Telefon: +90(478) 211 29 70</li>
        <li>Fax: +90(478) 211 30 36</li>
        <li>E-posta: ardahantso@tobb.org.tr</li>
      </ul>

      <ul style={{ color: textColor }}>
        <h5>Bağlantılar</h5>
        <li>
          <Link to='/kullanimSartlari' style={{ color: textColor }}>Kullanım Şartları</Link>
        </li>
        <li>
          <Link to='/gizlilikSozlesmesi' style={{ color: textColor }}>Gizlilik Sözleşmesi</Link>
        </li>
        <li>
          <Link to='/iletisim' style={{ color: textColor }}>İletişim Formu</Link>
        </li>
        <li>
          <Link to='/kurumsalLogo' style={{ color: textColor }}>Kurumsal Logo</Link>
        </li>
        <li>
          <Link to='/organizasyonSemasi' style={{ color: textColor }}>Kurum Organları</Link>
        </li>
      </ul>

      <ul style={{ color: textColor }}>
        <h5>Hakkımızda</h5>
        <li>
          <Link to='/tarihcemiz' style={{ color: textColor }}>Ardahan TSO Hakkında</Link>
        </li>
        <li>
          <Link to='/misyonVisyon' style={{ color: textColor }}>Misyon ve Vizyon</Link>
        </li>
        <li>
          <Link to='/kalitePolitikasi' style={{ color: textColor }}>Kalite Politikamız</Link>
        </li>
        <li>
          <Link to='/eskiBaşkanlarimiz' style={{ color: textColor }}>Eski Başkanlarımız</Link>
        </li>
        <li>
          <Link to='/kurumsalLogo' style={{ color: textColor }}>Kurumsal Logomuz</Link>
        </li>
      </ul>

      <ul style={{ color: textColor }}>
        <h5>Odamız</h5>
        <li>
          <Link to='/yonetimKuruluUyeleri' style={{ color: textColor }}>Yönetim Kurulu</Link>
        </li>
        <li>
          <Link to='/meclisUyeleri' style={{ color: textColor }}>Meclis Üyeleri</Link>
        </li>
        <li>
          <Link to='/tarihce' style={{ color: textColor }}>Ardahan Tarihçesi</Link>
        </li>
        <li>
          <Link to='/haberler' style={{ color: textColor }}>Faaliyetler</Link>
        </li>
      </ul>
    </footer>
  );
}
