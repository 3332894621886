import React, { useEffect, useState } from 'react';
import {

  VStack,
  HStack,
  Text,
  Box,
  Heading,
} from '@chakra-ui/react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_STRING;

const AnketSonuc = () => {
  const [summary, setSummary] = useState(null);

  const ratingOptions = ["Çok Zayıf", "Zayıf", "Orta", "İyi", "Çok İyi"];
  const duyuruKaynaklari = [
    "Telefon",
    "Faks",
    "Dergi",
    "Basın",
    "Komite Çalışmaları",
    "E-posta",
    "SMS",
    "Web Sayfası",
    "Diğer",
  ];


  useEffect(() => {
    axios
      .get(`${apiUrl}/summary`)
      .then((res) => {
        setSummary(res.data);
      })
      .catch((err) => {
        console.error("Özet veriler alınamadı:", err);
      });
  }, []);

  if (!summary) {
    return <Text>Yükleniyor...</Text>;
  }

  return (
    <VStack spacing={4}>
      <Box p={5} width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamızdan aldığınız hizmetlere ilişkin görüşlerinizi puanlayarak belirtiniz.
        </Heading>
        <VStack spacing={3} align="stretch">
          {Object.keys(summary["Değerlendirme (S5)"]).map((question) => (
            <Box p={5} border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white" key={question}>
              <Text fontWeight="bold">{question}</Text>
              <VStack spacing={2} align="stretch">
                {ratingOptions.map((option) => {
                  // Şık verileri varsa al, yoksa varsayılan değerler kullan
                  const { count = 0, percentage = "0%" } =
                    summary["Değerlendirme (S5)"][question][option] || {};
                  return (
                    <HStack key={option} justify="space-between">
                      <Text>{option}</Text>
                      <Text>{`${count} oy (${percentage})`}</Text>
                    </HStack>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamızın duyuruları ve yeni faaliyetleri hakkındaki bilgilerin size hangi kaynaktan ulaştığını belirtiniz. (S6)
        </Heading>
        <VStack spacing={3} align="stretch">
          {duyuruKaynaklari.map((source) => {
            const { count = 0, percentage = "0%" } =
              summary["Duyuru Kaynakları (S6)"][source] || {};
            return (
              <HStack key={source} justify="space-between">
                <Text>{source}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamızın duyuruları ve yeni faaliyetleri hakkındaki bilgilerin size hangi kaynaktan ulaşmasını tercih edersiniz. (S7)
        </Heading>
        <VStack spacing={3} align="stretch">
          {duyuruKaynaklari.map((source) => {
            const { count = 0, percentage = "0%" } =
              summary["Tercih Edilen Duyuru Kaynakları (S7)"]?.[source] || {};
            return (
              <HStack key={source} justify="space-between">
                <Text>{source}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamız tarafından düzenlenen Seminer, Eğitim ve Toplantı programlarına işletmenizin katılım sıklığını belirtiniz.
        </Heading>
        <VStack spacing={3} align="stretch">
          {["Çok Sık", "Ara Sıra", "Çok Seyrek", "Hiç", "Haberim Olmuyor"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Seminer Katılım Sıklığı (S8)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamız tarafından düzenlenen Seminer, Eğitim ve Toplantı programlarına katıldıysanız memnuniyet düzeyinizi belirtiniz.
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Kötü"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Seminer Memnuniyeti (S9)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          İşletmenizin aşağıdaki hangi konularda eğitime ihtiyacı olduğunu düşünüyorsunuz? (S10)
        </Heading>
        <VStack spacing={3} align="stretch">
          {[
            "Genel Yönetim",
            "Pazarlama Yönetimi",
            "Üretim Yönetimi",
            "Kalite Yönetimi",
            "İnsan Kaynakları Yönetimi",
            "Mali İşler ve Finans Yönetimi",
            "Dış Ticaret ve Uluslararası Mevzuat",
            "Bilgisayar ve Bilgi Teknolojileri",
            "Diğer",
          ].map((topic) => {
            const { count = 0, percentage = "0%" } = summary["Eğitim İhtiyaçları (S10)"]?.[topic] || {};
            return (
              <HStack key={topic} justify="space-between">
                <Text>{topic}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odanızın sizi ne düzeyde temsil ettiğine inanıyorsunuz?
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Zayıf"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Temsil Düzeyi (S12a)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamızın hizmet binasındaki "Yönlendirmeler" hakkındaki görüşünüz?
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Zayıf"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Yönlendirme Görüşü (S12b)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamızın hizmet binasının Genel Temizliği hakkındaki görüşünüz?
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Zayıf"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Genel Temizlik (S12c)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Odamızın hizmet binasının Çalışma Ortamının Fiziksel Yapısı (havalandırma, düzen/tertip, ferahlık vb.) hakkındaki görüşünüz?
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Zayıf"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Fiziksel Yapı (S12d)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white">
        <Heading as="h3" size="md" mb={4}>
          Gerektiğinde Üst Yönetime Ulaşabilme konusundaki görüşünüz?
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Zayıf"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Üst Yönetime Ulaşım (S12e)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>

      <Box p={5}  width="50%" border="1px solid #ccc" borderRadius="lg" boxShadow="lg" mb={6} bg="white" >
        <Heading as="h3" size="md" mb={4}>
          Üyesi olduğunuz Meslek Komitesinin çalışmaları hakkındaki görüşünüz?
        </Heading>
        <VStack spacing={3} align="stretch">
          {["İyi", "Orta", "Zayıf"].map((option) => {
            const { count = 0, percentage = "0%" } =
              summary["Komite Çalışmaları (S12f)"]?.[option] || {};
            return (
              <HStack key={option} justify="space-between">
                <Text>{option}</Text>
                <Text>{`${count} oy (${percentage})`}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>
    </VStack>
  );
};

export default AnketSonuc;