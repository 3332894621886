import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Carousel from 'react-bootstrap/Carousel';
import { Modal } from "react-bootstrap";

export default function Duyurular() {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const apiUrl = process.env.REACT_APP_API_STRING;
  const [showModal, setShowModal] = useState(false); 
  const [activeIndex, setActiveIndex] = useState(0); 

  const handleImageClick = (index) => {
    setActiveIndex(index); 
    setShowModal(true); 
  };

  const handleCloseModal = () => setShowModal(false); 


  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await fetch(`${apiUrl}/announcementDetail/${id}`);
        if (response.ok) {
          const data = await response.json();
          setAnnouncement(data);
        } else {
          console.error('Duyuru getirilemedi.');
        }
      } catch (err) {
        console.error('Sunucuya bağlanılamadı. Lütfen tekrar deneyin.', err);
      }
    };

    fetchAnnouncement();
  }, [id, apiUrl]);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Duyurular</title>
        <meta name="description" content="Duyurular" />
        <meta name="keywords" content="Duyurular" />
      </Helmet>

      <div className="row" id="announcementRow">
        <div className="col-12 col-sm-12 col-lg-8">
          {announcement ? (
            <div className="announcementDeneme">
              <h1>{announcement.announcement}</h1>
              <hr />

              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-6">
                    {announcement.announcementImgs && announcement.announcementImgs.length > 1 ? (
                      <Carousel>
                        {announcement.announcementImgs.map((img, index) => (
                          <Carousel.Item key={index}>
                            <img
                              src={`${apiUrl}/uploads/${img}`}
                              alt={`Duyuru Görseli ${index + 1}`}
                              className="d-block w-100"
                              onClick={() => handleImageClick(index)} // Resme tıklama olayını bağla
                              style={{ cursor: "pointer" }}
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    ) : (
                      <img
                        src={`${apiUrl}/uploads/${announcement.announcementImgs && announcement.announcementImgs.length === 1
                            ? announcement.announcementImgs[0]
                            : announcement.announcementImg
                          }`}
                        alt={announcement.announcement}
                        className="d-block w-100"
                        onClick={() => handleImageClick(0)} // Tek resim için tıklama olayını bağla
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                      <Modal.Body>
                        {announcement.announcementImgs && announcement.announcementImgs.length > 1 ? (
                          <Carousel activeIndex={activeIndex} onSelect={(index) => setActiveIndex(index)}>
                            {announcement.announcementImgs.map((img, index) => (
                              <Carousel.Item key={index}>
                                <img
                                  src={`${apiUrl}/uploads/${img}`}
                                  alt={`Duyuru Görseli ${index + 1}`}
                                  className="d-block w-100"
                                />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        ) : (
                          <img
                            src={`${apiUrl}/uploads/${announcement.announcementImgs && announcement.announcementImgs.length === 1
                                ? announcement.announcementImgs[0]
                                : announcement.announcementImg
                              }`}
                            alt={announcement.announcement}
                            className="d-block w-100"
                          />
                        )}
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    {announcement ? (
                      <p
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: announcement.announcementDetail,
                        }}
                      />
                    ) : (
                      <p>Yükleniyor</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    {announcement.announcement2 && (
                      <>
                        <h2 className="mt-3 text-center">{announcement.announcement2}</h2>
                        <p
                          className="mt-3"
                          dangerouslySetInnerHTML={{
                            __html: announcement.announcementDetail2,
                          }}
                        />
                      </>
                    )}
                    {announcement.announcement3 && (
                      <>
                        <h2 className="mt-5 text-center">{announcement.announcement3}</h2>
                        <p
                          className="mt-3"
                          dangerouslySetInnerHTML={{
                            __html: announcement.announcementDetail3,
                          }}
                        />
                      </>
                    )}


                    {announcement.announcementFile && (
                      <div className="sigorta">
                        <a
                          className="mevzuat"
                          href={`${apiUrl}/uploads/${announcement.announcementFile}`}
                          download
                        >
                          Dosyayı İndir
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>


          ) : (<p>Yükleniyor</p>)}
        </div>


      </div>
    </div>
  );
}
