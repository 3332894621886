import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/index.esm';
import 'bootstrap/js/index.umd';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ColorModeProvider } from './context/ColorModeContext';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

// Tema yapılandırması
const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? '#1a202c' : '#f1f1f1', 
        color: props.colorMode === 'dark' ? '#f1f1f1' : '#1a202c', 
      },
    }),
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <ColorModeProvider>
      <App />
    </ColorModeProvider>
  </ChakraProvider>
);
