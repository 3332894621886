import React, { useEffect, useState } from 'react'
import { faCommentsDollar, faFileInvoice, faUserTag, faCreditCard, faPoll, faPuzzlePiece, faFileAlt, faInfoCircle, faKiwiBird, faFileVideo, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Text, Grid, HStack, VStack, Icon, Button, Flex, Image, } from '@chakra-ui/react';
import { useCustomColorMode } from '../../context/ColorModeContext';
import Slider from 'react-slick';

export default function Home() {
    const { colorMode } = useCustomColorMode();
    const [announcements, setAnnouncements] = useState([]);
    const apiUrl = process.env.REACT_APP_API_STRING;
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(announcements.length / itemsPerPage);
    const handlePageChange = (pageIndex) => setCurrentPage(pageIndex);


    const displayedAnnouncements = announcements.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await fetch(`${apiUrl}/getAnnouncement`);
                if (response.ok) {
                    const data = await response.json();
                    setAnnouncements(data.reverse());
                } else {
                    console.error('Duyurular getirilemedi.');
                }
            } catch (err) {
                console.error('Sunucuya bağlanılamadı:', err);
            }
        };

        fetchAnnouncements();
    }, [apiUrl]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };


    return (
        <div className='container'>
            <Helmet>
                <title>Ardahan Ticaret ve Sanayi Odası</title>
                <meta name="description" content="Ardahan Ticaret ve Sanayi Odası" />
                <meta name="keywords" content="Ardahan Ticaret ve Sanayi Odası" />
            </Helmet>

            <div className='row homeCarousel'>
                <div className='col-lg-12 carouselContainer'>
                    <div className="row">
                        <div className="col-lg-12">

                            <div id="carouselExampleCaptions" className="carousel slide">
                                <div className="carousel-indicators">
                                    {announcements.map((_, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            data-bs-target="#carouselExampleCaptions"
                                            data-bs-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                            id='carouselBtn'
                                            aria-current={index === 0 ? "true" : undefined}
                                            aria-label={`Slide ${index + 1}`}
                                        ></button>
                                    ))}
                                </div>
                                <div className="carousel-inner">
                                    {announcements.map((announcement, index) => (
                                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                            <Link to={`/announcementDetail/${announcement._id}`}>
                                                <img
                                                    src={
                                                        announcement.announcementImgs?.length > 0
                                                            ? `${apiUrl}/uploads/${announcement.announcementImgs[0]}`
                                                            : `${apiUrl}/uploads/${announcement.announcementImg}`
                                                    }
                                                    className="d-block w-100"
                                                    alt={announcement.title}
                                                    style={{ height: '620px', objectFit: 'contain' }}
                                                />
                                            </Link>
                                            <div className="carousel-caption d-md-block" id='carouselTitle'>
                                                <h5>{announcement.announcement}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <Box className="col-lg-8">
                    <Box className="announcementsContainer" p={4} bg="gray.100" borderRadius="md" boxShadow="lg">
                        <HStack mb={4} alignItems="center" width="100%">
                            <Box flex="1" h="2px" bg="gray.300" />
                            <Text
                                className="text-center duyuruContainer"
                                fontSize="lg"
                                fontWeight="bold"
                                color="#000"
                                px={4}
                            >
                                Duyurular
                            </Text>
                            <Box flex="1" h="2px" bg="gray.300" />
                        </HStack>

                        <Box className="announcements">
                            <VStack spacing={3} align="stretch">
                                {displayedAnnouncements.map((announcement, index) => (
                                    <Link
                                        key={announcement._id || index}
                                        to={`/announcementDetail/${announcement._id}`}
                                        color="teal.500"
                                        fontWeight="bold"
                                        id='announcementsBox'
                                    >
                                        <Box
                                            p={3}
                                            bg="white"
                                            borderRadius="md"
                                            boxShadow="md"
                                            transition="all 0.2s"
                                            _hover={{ boxShadow: "lg" }}
                                        >
                                            <HStack spacing={3}>
                                                <Icon as={FontAwesomeIcon} icon={faNewspaper} />
                                                <Box id='announcementBoxs'>
                                                    <h5>{announcement.announcement}</h5>
                                                    <Text fontSize="sm" noOfLines={2}>
                                                        {announcement.announcementDetail.substring(0, 100)}...
                                                    </Text>
                                                </Box>
                                            </HStack>
                                        </Box>
                                    </Link>
                                ))}
                            </VStack>
                        </Box>

                        <HStack justifyContent="center" mt={4}>
                            {Array.from({ length: totalPages }).map((_, index) => (
                                <Button
                                    key={index}
                                    size="sm"
                                    borderRadius="full"
                                    bg={currentPage === index ? "yellow.500" : "gray.300"}
                                    onClick={() => handlePageChange(index)}
                                    _hover={{ bg: currentPage === index ? "teal.600" : "gray.400" }}
                                >
                                    {" "}
                                </Button>
                            ))}
                        </HStack>
                    </Box>
                </Box>

                <Box className="col-lg-4">
                    <Box className="presedentContainer" bg="gray.100" borderRadius="md" boxShadow="lg" overflow="hidden">
                        <Flex
                            className="innerAnnouncements"
                            align="center"
                            justify="center"
                            p={3}
                            bg="gray.100"
                            color={colorMode === "light" ? "#000" : "#fff"}
                            borderBottom="1px solid"
                            borderColor={colorMode === "light" ? "#f3ecd3" : "#1a202c"}
                        >
                            <HStack spacing={4} width="100%">
                                <Box flex="1" h="2px" bg="gray.300" />
                                <Text fontWeight="bold" fontSize="lg">
                                    Başkanımız
                                </Text>
                                <Box flex="1" h="2px" bg="gray.300" />
                            </HStack>
                        </Flex>

                        <Box className="presedent" textAlign="center">
                            <Image
                                src="/cetin-demirci-baskan.jpg"
                                alt="Baskan"
                                boxSize="150px"
                                borderRadius="full"
                                mx="auto"
                                objectFit="cover"
                                boxShadow="md"
                            />
                        </Box>


                        <Box className="presedentTitle" textAlign="center">
                            <Text fontSize="lg" fontWeight="bold" >
                                Çetin Demirci
                            </Text>
                            <Text fontSize="sm" >
                                Yönetim Kurulu Başkanı
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </div>

            <Box id="optionBoxs" w="100%" p={4}>
                <Grid
                    className="optionInner"
                    templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(5, 1fr)' }}
                    gap={6}
                >

                    <VStack className="options" style={{ backgroundColor: 'red' }} spacing={4} h="150px" >
                        <FontAwesomeIcon icon={faCommentsDollar} style={{ color: "#63E6BE", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} to="http://uygulama.tobb.net/UyeBilgiSorgulama/fw_uyeBilgiSorgulama.do">
                            Üye Borç Sorgulama
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#ff0066' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faUserTag} style={{ color: "#74C0FC", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} to="http://uygulama.tobb.net/UyeBilgiSorgulama/fw_uyeBilgiServisi.do">
                            Üye Bilgisi Sorgulama
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#114a75' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faFileInvoice} style={{ color: "#ff6251", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} href="#">
                            Ticaret Tescil ve Sicil
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#719085' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faCreditCard} style={{ color: "#3a88fe", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} to="https://uye.tobb.org.tr/hizliaidatodeme.jsp">
                            Online Aidat Ödeme
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#555' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faPoll} style={{ color: "#ff6251", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} href="#">
                            Anket ve Formlar
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#c73cae' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faPuzzlePiece} style={{ color: "#63E6BE", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} to="/belge.pdf" target="_blank" rel="noopener noreferrer">
                            Stratejik Plan
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#ff0066' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faFileAlt} style={{ color: "#96d35f", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} href="#">
                            Raporlar ve Yayınlar
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#9d6d34' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#00c7fc", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} href="#">
                            Kobi Bilgi Sistemi
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#4c693d' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faKiwiBird} style={{ color: "#96d35f", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} href="#">
                            Ardahan TSO Projeler
                        </Link>
                    </VStack>

                    <VStack className="options" style={{ backgroundColor: '#ff8a00' }} spacing={4} h="150px">
                        <FontAwesomeIcon icon={faFileVideo} style={{ color: "#ff6251", fontSize: "2rem" }} />
                        <Link style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }} href="#">
                            Video Galeri
                        </Link>
                    </VStack>
                </Grid>
            </Box>

            <Box w="100%" p={4}>
                <Box
                    bg={colorMode === "light" ? "#f0f0f0" : "#1a202c"}
                    color={colorMode === "light" ? "#000" : "#fff"}
                    borderRadius="md"
                    p={3}
                    textAlign="center"
                    mb={4}
                >
                    <HStack spacing={4} align="center">
                        <Box flex="1" h="2px" bg="gray.300" />
                        <Text fontWeight="bold" fontSize="lg" textAlign="center">
                            Hizmetlerimiz
                        </Text>
                        <Box flex="1" h="2px" bg="gray.300" />
                    </HStack>
                </Box>
                <Grid
                    templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                    gap={6}
                    justifyContent="center"
                >
                    <Box>
                        <Text as="ul" className='serviceContainer' listStyleType="none" m={0} p={0}>
                            <Text as="li">
                                <Link to="/tsicil_dilekce" color="teal.500" fontWeight="bold">
                                    Dilekçe İşlemleri
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/muhasebeFinansBirimi" color="teal.500" fontWeight="bold">
                                    Muhasebe ve Finans Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/sigortaKayıtBirimi" color="teal.500" fontWeight="bold">
                                    Sigortacılık Levha Kayıt Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/basinYayinDepartmani" color="teal.500" fontWeight="bold">
                                    Basın Yayın Departmanı
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/tpadkBirimi" color="teal.500" fontWeight="bold">
                                    TPADK (Alkol ve Tütün Ruhsatı) Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/egitimOrganizasyonBirimi" color="teal.500" fontWeight="bold">
                                    Eğitim ve Organizasyon Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/halklaIliskilerBirimi" color="teal.500" fontWeight="bold">
                                    Halkla İlişkiler Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/argeBirimi" color="teal.500" fontWeight="bold">
                                    AR-GE Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/bilgiIslemHizmetleri" color="teal.500" fontWeight="bold">
                                    Bilgi İşlem Hizmetleri
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/ticariVizeHizmetleri" color="teal.500" fontWeight="bold">
                                    Ticari Vize Hizmetleri
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/ticaretSicilMudurlugu" color="teal.500" fontWeight="bold">
                                    Ticaret Sicil Müdürlüğü
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/mevzuatKanunlar" color="teal.500" fontWeight="bold">
                                    Mevzuat ve Kanunlar
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/kBelgeYetkisi" color="teal.500" fontWeight="bold">
                                    "K" Türü Yetki Belgesi Bürosu
                                </Link>
                            </Text>
                        </Text>
                    </Box>
                    <Box>
                        <Text as="ul" className='serviceContainer' listStyleType="none" m={0} p={0}>
                            <Text as="li">
                                <Link to="/kapasiteRaporu" color="teal.500" fontWeight="bold">
                                    Kapasite Raporu İşlemleri
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/yaziIsleri" color="teal.500" fontWeight="bold">
                                    Yazı İşleri Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/deikBirimi" color="teal.500" fontWeight="bold">
                                    DEİK Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/igemeBirimi" color="teal.500" fontWeight="bold">
                                    İGEME Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/abKaliteAkreditasyon" color="teal.500" fontWeight="bold">
                                    AB - Kalite Ve Akreditasyon Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/yatirimDanısmanlıgı" color="teal.500" fontWeight="bold">
                                    Yatırım Danışmanlığı Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/insanKaynaklari" color="teal.500" fontWeight="bold">
                                    İnsan Kaynakları Birimi
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/odaSicilMemurlugu" color="teal.500" fontWeight="bold">
                                    Oda Sicil Memurluğu
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/isMakinesiIslemleri" color="teal.500" fontWeight="bold">
                                    İş Makinesi Tescil İşlemleri
                                </Link>
                            </Text>
                            <Text as="li">
                                <Link to="/ekspertizRaporu" color="teal.500" fontWeight="bold">
                                    Ekspertiz Raporu İşlemleri
                                </Link>
                            </Text>
                        </Text>
                    </Box>
                </Grid>
            </Box>

            <Flex justify="center" className="row">
                <Box className="col-lg-8" w="100%" maxW={1500}>
                    <Box
                        bg={colorMode === "light" ? "#f0f0f0" : "#1a202c"}
                        color={colorMode === "light" ? "#000" : "#fff"}
                        borderRadius="md"
                        p={3}
                        textAlign="center"
                        mb={4}
                    >
                        <HStack spacing={4} align="center">
                            <Box flex="1" h="2px" bg="gray.300" />
                            <Text fontWeight="bold" fontSize="lg" textAlign="center">
                                İşbirliği ve Fırsatlar
                            </Text>
                            <Box flex="1" h="2px" bg="gray.300" />
                        </HStack>
                    </Box>
                    <Flex className="opportunity" justify="center" gap={6} wrap="wrap">
                        <Link
                            href="https://www.halkbank.com.tr/21647-ise_devam_destegi___kucuk_isletme_can_suyu_kredisi"
                        >
                            <Image
                                src="/halkbank-can-suyu-kredisi.png"
                                alt="canSuyuKredisi"
                                borderRadius="md"
                                boxShadow="md"
                                transition="all 0.3s"
                                _hover={{ boxShadow: "lg" }}
                            />
                        </Link>

                        <Link
                            href="https://tobb.org.tr/TurkiyeSektorMeclisleri/Sayfalar/Trendyol.php"
                        >
                            <Image
                                src="/trendyol-tobb-isbirligi.jpg"
                                alt="trendyolTobb"
                                borderRadius="md"
                                boxShadow="md"
                                transition="all 0.3s"
                                _hover={{ boxShadow: "lg" }}
                            />
                        </Link>
                    </Flex>
                </Box>
            </Flex>

            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="employmentContainer">
                    <Box
                        bg={colorMode === "light" ? "#f0f0f0" : "#1a202c"}
                        color={colorMode === "light" ? "#000" : "#fff"}

                        borderRadius="md"
                        p={3}
                        mb={4}
                        textAlign="center"
                    >
                        <HStack spacing={4} align="center">
                            <Box flex="1" h="2px" bg="gray.300" />
                            <Text fontWeight="bold" fontSize="lg">

                                İstihdam Seferberliği
                            </Text>
                            <Box flex="1" h="2px" bg="gray.300" />
                        </HStack>
                    </Box>
                    <div className="employment">
                        <div className="row" style={{ width: '100%' }}>
                            <div className="col-sm-12 col-lg-6">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/fKYStVyt_sk?si=jqdwKIK5UB9RxPgD" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/LdWLehChMO0?si=wmo0bpiaCsT_D6kI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="col-lg-12">
                    <Box
                        bg={colorMode === "light" ? "#f0f0f0" : "#1a202c"}
                        color={colorMode === "light" ? "#000" : "#fff"}

                        borderRadius="md"
                        p={3}
                        mb={4}
                        textAlign="center"
                    >
                        <HStack spacing={4} align="center">
                            <Box flex="1" h="2px" bg="gray.300" />
                            <Text fontWeight="bold" fontSize="lg">

                                TOBB2B Ticarette Birliğin Gücünü Keşfedin
                            </Text>
                            <Box flex="1" h="2px" bg="gray.300" />
                        </HStack>
                    </Box>

                    <div className="cardContainer">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <Link to="http://www.tobb2b.org.tr/ihracat.php">
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="/tobb2b_satisyapmakistiyorum.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <p className="card-text">Satış Yapmak İstiyorum</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <Link to="http://www.tobb2b.org.tr/ithalat.php">
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="/tobb2b_alimyapmakistiyorum.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <p className="card-text">Alım Yapmak İstiyorum</p>
                                        </div>
                                    </div>
                                </Link>

                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <Link to="http://www.tobb2b.org.tr/isbirligi.php">
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="/tobb2b_isbirligiyapmakistiyorum.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <p className="card-text">İşbirliği Yapmak İstiyorum</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <Link to="http://www.tobb2b.org.tr/ihale.php">
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="/tobb2b_ihaleveozellestirmeler.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <p className="card-text">İhale ve Özelleştirmeler</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>











                    </div>
                </div>
            </div>

            <div className="row d-flex justify-content-center" style={{ marginTop: '3rem', marginBottom: '3rem' }}>

                <div className="col-lg-12">
                    <Box
                        bg="#f0f0f0"
                        color="#000"
                        borderRadius="md"
                        p={3}
                        mb={4}
                        textAlign="center"
                    >
                        <HStack spacing={4} align="center">
                            <Box flex="1" h="2px" bg="gray.300" />
                            <Text fontWeight="bold" fontSize="lg">
                                Anlaşmalı İş Yerleri
                            </Text>
                            <Box flex="1" h="2px" bg="gray.300" />
                        </HStack>
                    </Box>

                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>

                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <Link to="https://www.evginler.com" target="_blank">
                                    <img src="/evginler.jpg" className="d-block w-50 brandLogo" alt="Evginler" />
                                </Link>
                            </div>
                            <div className="carousel-item">
                                <Link to="https://agaradogalveyoresel.com" target="_blank">
                                    <img src="/agara.jpeg" className="d-block w-50 brandLogo" alt="Agaradogalveyoresel" />
                                </Link>
                            </div>
                            <div className="carousel-item">
                                <Link to="https://www.yanatlisut.com" target="_blank">
                                    <img src="/yanatlı.png" className="d-block w-50 brandLogo" alt="Yanatlı" />
                                </Link>
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-12 col-lg-12 carousel-container sliderCarousel">
                    <Box
                        bg={colorMode === "light" ? "#f0f0f0" : "#1a202c"}
                        color={colorMode === "light" ? "#000" : "#fff"}

                        borderRadius="md"
                        p={3}
                        mb={4}
                        textAlign="center"
                    >
                        <HStack spacing={4} align="center">
                            <Box flex="1" h="2px" bg="gray.300" />
                            <Text fontWeight="bold" fontSize="lg">
                                İlişkili Kurumlar
                            </Text>
                            <Box flex="1" h="2px" bg="gray.300" />
                        </HStack>
                    </Box>
                    <Slider {...settings}>
                        <div className='sliderCarouselInner'>
                            <Link to='https://www.tse.org.tr'>
                                <img src="https://files.tse.org.tr//2023/06/TSE.png" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.sgk.gov.tr'>
                                <img src="https://www.sgk.gov.tr/cdn/v2/common/img/kurumsal%20logo-2.png" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='#'>
                                <img src="https://i0.wp.com/www.yuksekbilgili.com/wordpress/wp-content/uploads/2020/11/abigem.png?fit=250%2C250&ssl=1" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.ticaret.gov.tr'>
                                <img src="https://seeklogo.com/images/G/gumruk-ve-ticaret-bakanligi-logo-4E0877BDD9-seeklogo.com.png" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.tarimorman.gov.tr'>
                                <img src="https://logowik.com/content/uploads/images/980_gidatarimbakanligi.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.tuik.gov.tr'>
                                <img src="https://seeklogo.com/images/T/turkiye-istatistik-kurumu-yeni-logo-DD6665118F-seeklogo.com.png" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.etu.edu.tr/tr'>
                                <img src="https://www.etu.edu.tr/files/logolar/standart_logo/yatay/tr/tobb_etu_yatay_tr.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.tepav.org.tr/tr/'>
                                <img src="https://www.tepav.org.tr/upload/tepav-m.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.kgf.com.tr/index.php/tr/'>
                                <img src="https://www.rtb.org.tr/uploads/files/347-mmmmmm.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='http://www.balo.tc'>
                                <img src="https://www.kirklarelitb.org.tr/upload/yazi/byk-anadolu-lojstk-organzasyonlar-a_7f7f37ad75511fe613052024004316000000.webp" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.gtias.com.tr'>
                                <img src="https://www.kirklarelitb.org.tr/upload/yazi/gmrk-ve-turzm-letmeler-tcaret-a_a98d424bf17fb10813052024003911000000.webp" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://meybem.org.tr'>
                                <img src="https://www.rtb.org.tr/uploads/696-tobb-meybem-mesleki-yeterlilik-ve-belgelendirme-hizmetleri-image1.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.rekabet.gov.tr'>
                                <img src="https://logowik.com/content/uploads/images/rekabet-kurumu8471.logowik.com.webp" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.iskur.gov.tr'>
                                <img src="https://sehrivangazetesicom.teimg.com/crop/1280x720/sehrivangazetesi-com/uploads/2023/05/iskurrr.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.eximbank.gov.tr'>
                                <img src="https://www.eximbank.gov.tr/Content/images/eximbank-logo.svg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.tobb.org.tr/TOBBKadinGirisimcilerKurulu/Sayfalar/AnaSayfa.php'>
                                <img src="https://www.tobb.org.tr/TOBBKadinGirisimcilerKurulu/Resimler/Logolar/yatay-1.png" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.tobb.org.tr/TOBBGencGirisimcilerKurulu/Sayfalar/AnaSayfa.php'>
                                <img src="https://www.tobb.org.tr/TOBBGencGirisimcilerKurulu/foto/GGKLogo.jpg" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.kosgeb.gov.tr'>
                                <img src="https://i.pinimg.com/originals/ee/d7/48/eed748bdd0076e70345ec2f4edb9720b.png" alt="..." />
                            </Link>
                        </div>

                        <div className='sliderCarouselInner'>
                            <Link to='https://www.resmigazete.gov.tr'>
                                <img src="https://seeklogo.com/images/E/e-devlet-sembol-logo-6F971085F4-seeklogo.com.png" alt="..." />
                            </Link>
                        </div>
                    </Slider>
                </div>
            </div>

        </div>
    )
}


