import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios'

export default function Dilekce() {
    const formRef = useRef();
    const [naceData, setNaceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [errors, setErrors] = useState({});
    const apiUrl = process.env.REACT_APP_API_STRING;
    const [selectedNaceCode, setSelectedNaceCode] = useState({
        code: '',
        description: '',
    });

    const [formData, setFormData] = useState({
        firmaTuru: '',
        islemTipi: '',
        unvan: '',
        vergiNo: '',
        adres: '',
        sermaye: '',
        sermayeDurumu: '',
        yetkiliAdi: '',
        yetkiliSoyadi: '',
        telefon: '',
        email: '',
        mersisNo: '',
        sicilMudurlugu: '',
        sicilNo: '',
        randevuNo: '',
        naceCode: '',
        naceDescription: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

       
        setFormData((prevData) => ({ ...prevData, [name]: value }));

       
        if (name === 'islemTipi' && value === 'Kuruluş') {
            setFormData((prevData) => ({ ...prevData, sermayeDurumu: '' }));
            setErrors((prevErrors) => ({ ...prevErrors, sermayeDurumu: '' }));
        }

       
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

   
    const isSermayeDurumuDisabled = formData.islemTipi === 'Kuruluş';

    useEffect(() => {
        fetch('/nace.json')
            .then((response) => response.json())
            .then((data) => {
                setNaceData(data);
                setFilteredData(data);
            });
    }, []);

    const toggleModal = () => {
        console.log("Modal Durumu Değişiyor, Mevcut Form Verileri:", formData);
        setModalVisible(!modalVisible);
        setSearchQuery('');
        setFilteredData(naceData);
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = naceData.filter(
            (item) =>
                item.A.toLowerCase().includes(query) ||
                item.B.toLowerCase().includes(query)
        );
        setFilteredData(filtered);
    };

    const handleSelectNace = (code, description) => {
        setSelectedNaceCode({ code, description });
        setFormData((prevData) => ({
            ...prevData,
            naceCode: code,
            naceDescription: description,
        }));
        toggleModal();
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firmaTuru) newErrors.firmaTuru = 'Firma Türü alanını doldurun.';
        if (!formData.islemTipi) newErrors.islemTipi = 'İşlem Tipi alanını doldurun.';
        if (!formData.unvan) newErrors.unvan = 'İşletmenin Ünvanı alanını doldurun.';
        if (!formData.vergiNo) newErrors.vergiNo = 'Potansiyel Vergi Numarası alanını doldurun.';
        if (!formData.adres) newErrors.adres = 'Adres alanını doldurun.';
        if (!formData.sermaye) newErrors.sermaye = 'Sermaye alanını doldurun.';
        if (!formData.sermayeDurumu) newErrors.sermayeDurumu = 'Sermaye Durumu alanını doldurun.';
        if (!formData.yetkiliAdi) newErrors.yetkiliAdi = 'Yetkili Adı alanını doldurun.';
        if (!formData.yetkiliSoyadi) newErrors.yetkiliSoyadi = 'Yetkili Soyadı alanını doldurun.';
        if (!formData.sicilNo) newErrors.sicilNo = 'Merkez Ticaret Sicil No alanını doldurun.';
        if (!formData.sicilMudurlugu) newErrors.sicilMudurlugu = 'Merkez Ticaret Sicil Müdürlüğü alanını Merkez olarak doldurun.';


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handlePrint = () => {
        window.print();
    };

    const handleGeneratePDF = () => {
        if (!validateForm()) return;
        axios
            .post(`${apiUrl}/dilekce`, { formData }, { responseType: "blob" })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "dilekce.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => console.error("PDF oluşturulamadı:", error));
    };

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', border: '1px solid #ddd', borderRadius: '10px' }}>
            <div className="form-container form-grid" ref={formRef}>
                <h2 style={{ textAlign: 'center' }}>Dilekçe</h2>
                <form>
                    <div>
                        <label>Firma Türü: </label>
                        <select
                            name="firmaTuru"
                            value={formData.firmaTuru}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Seçiniz</option>
                            <option>Anonim Şirket</option>
                            <option>Adi Komandit Şirket</option>
                            <option>Adi Ortaklık</option>
                            <option>Birlik</option>
                            <option>Depo</option>
                            <option>Dernek İşletme</option>
                            <option>Donatma İştiraki</option>
                            <option>İş Ortaklığı</option>
                            <option>İrtibat Bürosu</option>
                            <option>Kamu Tüzel Kişi İşl.</option>
                            <option>Kollektif Şirket</option>
                            <option>Komandit Şirket</option>
                            <option>Konsorsiyum</option>
                            <option>Kooperatif</option>
                            <option>Limited Şirket</option>
                            <option>Ticari İşletme</option>
                            <option>Özel Statülü Şirket</option>
                            <option>Şube</option>
                            <option>Vakıf İşletmeleri</option>
                            <option>Diğer-İktisadi İşl.</option>
                        </select>
                        {errors.firmaTuru && <p style={{ color: 'red' }}>{errors.firmaTuru}</p>}
                    </div>
                    <div>
                        <label>İşlem Tipi: </label>
                        <select
                            name="islemTipi"
                            value={formData.islemTipi}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Seçiniz</option>
                            <option>Kuruluş</option>
                            <option>Merkez Nakil</option>
                            <option>Şube Açılışı</option>
                        </select>
                        {errors.islemTipi && <p style={{ color: 'red' }}>{errors.islemTipi}</p>}
                    </div>
                    <div>
                        <label>İşletmenin Unvanı: </label>
                        <input
                            type="text" name="unvan"
                            value={formData.unvan}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.unvan && <p style={{ color: 'red' }}>{errors.unvan}</p>}
                    </div>
                    <div>
                        <label>Potansiyel Vergi Numarası: </label>
                        <input
                            type="text"
                            name="vergiNo"
                            value={formData.vergiNo}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.vergiNo && <p style={{ color: 'red' }}>{errors.vergiNo}</p>}
                    </div>
                    <div>
                        <label>Adres: </label>
                        <textarea
                            name="adres"
                            value={formData.adres}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.adres && <p style={{ color: 'red' }}>{errors.adres}</p>}
                    </div>
                    <div>
                        <label>Sermayesi: </label>
                        <input
                            type="number"
                            name="sermaye"
                            step="0.01"
                            value={formData.sermaye}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.sermaye && <p style={{ color: 'red' }}>{errors.sermaye}</p>}
                    </div>
                    <div>
                        <label>Sermaye Durumu: </label>
                        <select
                            name="sermayeDurumu"
                            value={formData.sermayeDurumu}
                            onChange={handleInputChange}
                            disabled={isSermayeDurumuDisabled}
                            required={!isSermayeDurumuDisabled}
                            className={isSermayeDurumuDisabled ? 'disabled-select' : ''}
                        >
                            <option value="">Seçiniz</option>
                            <option>Sermayeye Ayrılmamış</option>
                            <option>Sermayesi Merkezden Karşılanmıştır</option>
                        </select>
                        {errors.sermayeDurumu && <p style={{ color: 'red' }}>{errors.sermayeDurumu}</p>}
                    </div>
                    <div>
                        <label>İşletmenin Açılış Tarihindeki Gerçek Faaliyet Konusu NACE Kodu: </label>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <input
                                type="text"
                                value={selectedNaceCode.code}
                                placeholder="NACE Kodu" readOnly
                            />
                            <input
                                type="text"
                                value={selectedNaceCode.description}
                                placeholder="NACE Açıklaması"
                                readOnly
                            />
                            <button
                                type="button"
                                onClick={toggleModal}>
                                NACE Kodu Seç
                            </button>
                        </div>
                    </div>
                    <div>
                        <label>İşletme Yetkilisinin Adı: </label>
                        <input
                            type="text"
                            name="yetkiliAdi"
                            value={formData.yetkiliAdi}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.yetkiliAdi && <p style={{ color: 'red' }}>{errors.yetkiliAdi}</p>}
                    </div>
                    <div>
                        <label>İşletme Yetkilisinin Soyadı: </label>
                        <input
                            type="text"
                            name="yetkiliSoyadi"
                            value={formData.yetkiliSoyadi}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.yetkiliSoyadi && <p style={{ color: 'red' }}>{errors.yetkiliSoyadi}</p>}
                    </div>
                    <div>
                        <label>Telefon Numarası </label>
                        <input
                            type="phone"
                            name="telefon"
                            value={formData.telefon}
                            onChange={handleInputChange}
                            placeholder='+90 5555555555'
                        />
                        <br />
                        <label>E-Posta Adresi: </label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder='ornek@hotmail.com'
                        />
                    </div>
                    <div>
                        <label>Mersis Başvuru Talep No: </label>
                        <input
                            type="text"
                            name="mersisNo"
                            value={formData.mersisNo}
                            onChange={handleInputChange}
                        />
                        {errors.mersisNo && <p style={{ color: 'red' }}>{errors.mersisNo}</p>}
                    </div>
                    <div>
                        <label>Kuruluş Randevu Takip No: </label>
                        <input
                            type="text"
                            name="randevuNo"
                            value={formData.randevuNo}
                            onChange={handleInputChange}
                        />
                        {errors.randevuNo && <p style={{ color: 'red' }}>{errors.randevuNo}</p>}
                    </div>

                    <div>
                        <label>Merkezin Kayıtlı Olduğu Ticaret Sicili Müdürlüğü : </label>
                        <input
                            type="text"
                            name="sicilMudurlugu"
                            value={formData.sicilMudurlugu}
                            onChange={handleInputChange}
                            placeholder='Örn: MERKEZ'
                        />
                        {errors.sicilMudurlugu && <p style={{ color: 'red' }}>{errors.sicilMudurlugu}</p>}
                    </div>

                    <div>
                        <label>Merkez Ticaret Sicil No :</label>
                        <input
                            type="text"
                            name="sicilNo"
                            value={formData.sicilNo}
                            onChange={handleInputChange}
                            placeholder='Örn: 3156'
                            required
                        />
                        {errors.sicilNo && <p style={{ color: 'red' }}>{errors.sicilNo}</p>}
                    </div>
                </form>

            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button onClick={handleGeneratePDF} style={{ marginLeft: '10px' }}>
                    PDF Olarak İndir
                </button>
            </div>
            {modalVisible && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '10px', width: '80%', maxHeight: '80%', overflowY: 'auto' }}>
                        <h3>NACE Kodları</h3>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder="Arama yap..."
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>NACE Kodu</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>NACE Adı</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Seç</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.A}</td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.B}</td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                            <button onClick={() => handleSelectNace(item.A, item.B)}>Seç</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button onClick={toggleModal} style={{ marginTop: '10px' }}>
                            Kapat
                        </button>
                    </div>
                </div>
            )}
        </div>

    );
}
