import React from 'react';
import { Box, Text, Grid, Link as ChakraLink, Image } from '@chakra-ui/react';

export default function IsYerleri() {
  const isYerleri = [
    {
      name: "Evginler",
      img: "/evginler.jpg",
      link: "https://www.evginler.com",
    },
    {
      name: "Agara Doğal ve Yöresel",
      img: "/agara.jpeg",
      link: "https://agaradogalveyoresel.com",
    },
    {
      name: "Yanatlı Süt",
      img: "/yanatlı.png",
      link: "https://www.yanatlisut.com",
    },
  ];

  return (
    <div className='container'>
      <Box
        bg="#f0f0f0"
        color="#000"
        borderRadius="md"
        p={3}
        mb={4}
        textAlign="center"
      >
        <Text fontWeight="bold" fontSize="lg">
          Anlaşmalı İş Yerleri
        </Text>
      </Box>

      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {isYerleri.map((isYeri, index) => (
          <Box
            key={index}
            bg="white"
            borderRadius="md"
            boxShadow="md"
            overflow="hidden"
            textAlign="center"
            p={4}
            _hover={{ transform: "scale(1.05)", transition: "0.3s ease" }}
          >
            <ChakraLink href={isYeri.link} target="_blank">
              <Image
                src={isYeri.img}
                alt={isYeri.name}
                objectFit="cover"
                w="100%"
                h="250px"
                mb={3}
              />
              <Text fontWeight="bold">{isYeri.name}</Text>
            </ChakraLink>
          </Box>
        ))}
      </Grid>
    </div>
  );
}
