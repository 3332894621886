import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Select,
    Input,
    Textarea,
    VStack,
    HStack,
    Text,
    Box,
    Heading,
    Checkbox,
    CheckboxGroup,
    RadioGroup,
    Radio,
    Stack
} from '@chakra-ui/react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_STRING;

export default function SurveyModal({ isOpen, onClose }) {
    const [formData, setFormData] = useState({
        companyStatus: '',
        activityType: '',
        companyScale: '',
        membershipYears: '',
        ratings: {
            personelDavranisi: '',
            hizmetSuresi: '',
            bilgiYeterliligi: '',
            kaynakYeterliligi: '',
            talepKarsilama: '',
        },
        additionalFeedback: ['',],
        announcementSources: [],
        preferredAnnouncementSources: [],
        seminarParticipationFrequency: '',
        seminarSatisfaction: '',
        trainingNeeds: [],
        representationLevel: '',
        guidance: '',
        cleanliness: '',
        physicalStructure: '',
        managementAccess: '',
        committeeWork: '',
        //otherTrainingNeeds:'',
        representationLevel: '',
        guidance: '',
        cleanliness: '',
        physicalStructure: '',
        managementAccess: '',
        committeeWork: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleArrayInputChange = (index, value) => {
        setFormData((prev) => {
            const newFeedback = [...prev.additionalFeedback];
            newFeedback[index] = value || "";
            return { ...prev, additionalFeedback: newFeedback };
        });
    };

    const handleRatingsChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            ratings: { ...prev.ratings, [field]: value },
        }));
    };

    const handleCheckboxChange = (field, values) => {
        setFormData((prev) => ({ ...prev, [field]: values }));
    };

    const handleSubmit = async () => {
       
        for (const key in formData) {
            if (
                (Array.isArray(formData[key]) && formData[key].length === 0) || 
                (!Array.isArray(formData[key]) && formData[key] === '') 
            ) {
                alert(`Eksik alan: ${key}`);
                return; 
            }
        }

       
        console.log('Gönderilen Veri:', formData);
        try {
            await axios.post(`${apiUrl}/submit-survey`, formData);
            alert('Anket gönderildi!');
            onClose();
        } catch (error) {
            console.error('Anket gönderim hatası:', error);
            alert('Anket gönderilirken bir hata oluştu.');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <VStack className='surveyTitleContainer'/>
                <ModalHeader textAlign="center">Üye Memnuniyet Anketi</ModalHeader>
                <ModalBody>
                    <VStack spacing={4} align="stretch">
                        
                        <Text>Kuruluş Statüsü:</Text>
                        <Select name="companyStatus" onChange={handleInputChange} placeholder="Seçiniz">
                            <option value="Şahıs Firması">Şahıs Firması</option>
                            <option value="Limited Şirket">Limited Şirket</option>
                            <option value="Anonim Şirket">Anonim Şirket</option>
                            <option value="Kollektif Şirket">Kollektif Şirket</option>
                            <option value="Komandit Şirket">Komandit Şirket</option>
                            <option value="Kooperatif">Kooperatif</option>
                            <option value="Diğer">Diğer</option>
                        </Select>
                       
                        <Text>Faaliyet Türü:</Text>
                        <Select name="activityType" onChange={handleInputChange} placeholder="Seçiniz">
                            <option value="Ticaret">Ticaret</option>
                            <option value="Hizmet">Hizmet</option>
                            <option value="İmalat">İmalat</option>
                            <option value="Turizm">Turizm</option>
                            <option value="Ulaştırma">Ulaştırma</option>
                            <option value="Tarım-Hayvancılık">Tarım-Hayvancılık</option>
                            <option value="Diğer">Diğer</option>
                        </Select>
                        
                        <Text>İşletme Ölçeği (Çalışan Personel Sayısı):</Text>
                        <Select name="companyScale" onChange={handleInputChange} placeholder="Seçiniz">
                            <option value="1-9">(1-9)</option>
                            <option value="10-49">(10-49)</option>
                            <option value="50-249">(50-249)</option>
                            <option value="250 ve üzeri">(250 ve üzeri)</option>
                        </Select>
                        
                        <Text>Odaya Kaç Yıldır Üyesiniz?</Text>
                        <Input
                            type="number"
                            name="membershipYears"
                            placeholder="Yıl sayısı"
                            onChange={handleInputChange}
                        />

                        
                        <Box p={5} border="1px solid #ccc" borderRadius="md" boxShadow="sm">
                            <Heading as="h3" size="md" mb={4}>
                                Odamızdan aldığınız hizmetlere ilişkin görüşlerinizi puanlayarak belirtiniz.
                            </Heading>
                            <VStack spacing={4} align="stretch">
                                {[
                                    { label: 'Personelin davranışı', field: 'personelDavranisi' },
                                    { label: 'Hizmet Süresi', field: 'hizmetSuresi' },
                                    { label: 'Bilgi Yeterliliği', field: 'bilgiYeterliligi' },
                                    { label: 'Kaynak Yeterliliği', field: 'kaynakYeterliligi' },
                                    { label: 'Talebin karşılanma düzeyi', field: 'talepKarsilama' },
                                ].map(({ label, field }) => (
                                    <HStack key={field} justify="space-between" w="100%">
                                        <Text>{label}</Text>
                                        <Select
                                            name={field}
                                            value={formData.ratings[field] || ''}
                                            onChange={(e) => handleRatingsChange(field, e.target.value)}
                                            w="150px"
                                        >
                                            <option value="">Seçiniz</option>
                                            <option value="Çok Zayıf">Çok Zayıf</option>
                                            <option value="Zayıf">Zayıf</option>
                                            <option value="Orta">Orta</option>
                                            <option value="İyi">İyi</option>
                                            <option value="Çok İyi">Çok İyi</option>
                                        </Select>
                                    </HStack>
                                ))}
                            </VStack>
                        </Box>

                       
                        <Box p={5} border="1px solid #ccc" borderRadius="md" boxShadow="sm">
                            <VStack spacing={6} align="stretch">
                                <Box>
                                    <Heading as="h3" size="md" mb={4}>
                                        S2-) Odamızın duyuruları ve yeni faaliyetleri hakkındaki bilgilerin size hangi kaynaktan ulaştığını belirtiniz.
                                    </Heading>
                                    <CheckboxGroup
                                        value={formData.announcementSources}
                                        onChange={(values) => handleCheckboxChange("announcementSources", values)}
                                    >
                                        <VStack align="start">
                                            {['Telefon', 'Faks', 'Dergi', 'Basın', 'Komite Çalışmaları', 'E-posta', 'SMS', 'Web Sayfası', 'Diğer'].map(
                                                (option, index) => (
                                                    <Checkbox key={index} value={option}>
                                                        {option}
                                                    </Checkbox>
                                                )
                                            )}
                                        </VStack>
                                    </CheckboxGroup>
                                </Box>


                                <Box>
                                    <Heading as="h3" size="md" mb={4}>
                                        S3-) Odamızın duyuruları ve yeni faaliyetleri hakkındaki bilgilerin size hangi kaynaktan ulaşmasını tercih edersiniz.
                                    </Heading>
                                    <CheckboxGroup
                                        value={formData.preferredAnnouncementSources}
                                        onChange={(values) => handleCheckboxChange("preferredAnnouncementSources", values)}
                                    >
                                        <VStack align="start">
                                            {['Telefon', 'Faks', 'Dergi', 'Basın', 'Komite Çalışmaları', 'E-posta', 'SMS', 'Web Sayfası', 'Diğer'].map(
                                                (option, index) => (
                                                    <Checkbox key={index} value={option}>
                                                        {option}
                                                    </Checkbox>
                                                )
                                            )}
                                        </VStack>
                                    </CheckboxGroup>
                                </Box>


                                <Box>
                                    <Heading as="h3" size="md" mb={4}>
                                        S4-) Odamız tarafından düzenlenen Seminer, Eğitim ve Toplantı programlarına işletmenizin katılım sıklığını belirtiniz.
                                    </Heading>
                                    <RadioGroup
                                        value={formData.seminarParticipationFrequency}
                                        onChange={(value) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                seminarParticipationFrequency: value,
                                            }))
                                        }
                                    >
                                        <VStack align="start">
                                            {['Çok Sık', 'Ara Sıra', 'Çok Seyrek', 'Hiç', 'Haberim Olmuyor'].map((option, index) => (
                                                <Radio key={index} value={option}>
                                                    {option}
                                                </Radio>
                                            ))}
                                        </VStack>
                                    </RadioGroup>
                                </Box>


                                <Box>
                                    <Heading as="h3" size="md" mb={4}>
                                        S5-) Odamız tarafından düzenlenen Seminer, Eğitim ve Toplantı programlarına katıldıysanız memnuniyet düzeyinizi belirtiniz.
                                    </Heading>
                                    <RadioGroup
                                        value={formData.seminarSatisfaction}
                                        onChange={(value) => handleInputChange({ target: { name: "seminarSatisfaction", value } })}
                                    >
                                        <VStack align="start">
                                            {['İyi', 'Orta', 'Kötü'].map((option, index) => (
                                                <Radio key={index} value={option}>
                                                    {option}
                                                </Radio>
                                            ))}
                                        </VStack>
                                    </RadioGroup>
                                </Box>
                            </VStack>
                        </Box>
                       
                        <Box p={5} border="1px solid #ccc" borderRadius="md" boxShadow="sm">
                            <Heading as="h3" size="md" mb={4}>
                                S6-) İşletmenizin aşağıdaki hangi konularda eğitime ihtiyacı olduğunu düşünüyorsunuz?
                            </Heading>
                            <CheckboxGroup
                                value={formData.trainingNeeds}
                                onChange={(values) => handleCheckboxChange("trainingNeeds", values)}
                            >
                                <VStack align="start">
                                    <Checkbox value="Genel Yönetim">
                                        Genel Yönetim (Kurumsallaşma, Yönetim Modelleri, Stratejik Planlama, İş Kurma ve Geliştirme vb.)
                                    </Checkbox>
                                    <Checkbox value="Pazarlama Yönetimi">
                                        Pazarlama Yönetimi (Satış ve Pazarlama Yönetimi, Pazar Araştırması, Dağıtım, Fiyatlandırma vb.)
                                    </Checkbox>
                                    <Checkbox value="Üretim Yönetimi">
                                        Üretim Yönetimi (Üretim Planlama, Malzeme Yönetimi, Otomasyon, Teknik Konular vb.)
                                    </Checkbox>
                                    <Checkbox value="Kalite Yönetimi">
                                        Kalite Yönetimi (ISO 9000, ISO 14000, OHSAS, HACCP, Ürün Belgelendirme, CE vb.)
                                    </Checkbox>
                                    <Checkbox value="İnsan Kaynakları Yönetimi">
                                        İnsan Kaynakları Yönetimi (İşe Alma, Kariyer/İşgücü Planlama, Performans Yönetimi vb.)
                                    </Checkbox>
                                    <Checkbox value="Mali İşler ve Finans Yönetimi">
                                        Mali İşler ve Finans Yönetimi (Ekonomik Planlama ve Kontrol, Finansal Planlama vb.)
                                    </Checkbox>
                                    <Checkbox value="Dış Ticaret ve Uluslararası Mevzuat">
                                        Dış Ticaret ve Uluslararası Mevzuat (Dış Ticaret Mevzuatı, Ödeme Şekilleri, İth./İhr. İşlemleri vb.)
                                    </Checkbox>
                                    <Checkbox value="Bilgisayar ve Bilgi Teknolojileri">
                                        Bilgisayar ve Bilgi Teknolojileri (İnternet, E-Ticaret, Office Programları, ERP, MRP vb.)
                                    </Checkbox>
                                    {/* <Checkbox value="Diğer">
                                        <VStack align="start" mt={2}>
                                            <Text>Diğer:</Text>
                                            <Input
                                                name="otherTrainingNeeds"
                                                placeholder="Diğer konuları yazınız..."
                                                value={formData.otherTrainingNeeds}
                                                onChange={handleInputChange}
                                            />
                                        </VStack>
                                    </Checkbox> */}
                                </VStack>
                            </CheckboxGroup>
                        </Box>
                     
                        <Box p={5} border="1px solid #ccc" borderRadius="md" boxShadow="sm">
                            <Heading as="h3" size="md" mb={4}>
                                S7-) Odanızın hizmet binası ve çalışmaları ile ilgili görüşlerinizi belirtiniz:
                            </Heading>
                            <VStack align="stretch" spacing={4}>
                                <Box>
                                    <Text>a) Odanızın sizi ne düzeyde temsil ettiğine inanıyorsunuz?</Text>
                                    <RadioGroup
                                        value={formData.representationLevel}
                                        onChange={(value) =>
                                            handleInputChange({ target: { name: 'representationLevel', value } })
                                        }
                                    >
                                        <Stack direction="row">
                                            <Radio value="Zayıf">Zayıf</Radio>
                                            <Radio value="Orta">Orta</Radio>
                                            <Radio value="İyi">İyi</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Box>

                                
                                <Box>
                                    <Text>b) Odamızın hizmet binasındaki "Yönlendirmeler" hakkındaki görüşünüz?</Text>
                                    <RadioGroup
                                        value={formData.guidance}
                                        onChange={(value) =>
                                            handleInputChange({ target: { name: 'guidance', value } })
                                        }
                                    >
                                        <Stack direction="row">
                                            <Radio value="Zayıf">Zayıf</Radio>
                                            <Radio value="Orta">Orta</Radio>
                                            <Radio value="İyi">İyi</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Box>

                               
                                <Box>
                                    <Text>c) Odamızın hizmet binasının Genel Temizliği hakkındaki görüşünüz?</Text>
                                    <RadioGroup
                                        value={formData.cleanliness}
                                        onChange={(value) =>
                                            handleInputChange({ target: { name: 'cleanliness', value } })
                                        }
                                    >
                                        <Stack direction="row">
                                            <Radio value="Zayıf">Zayıf</Radio>
                                            <Radio value="Orta">Orta</Radio>
                                            <Radio value="İyi">İyi</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Box>

                               
                                <Box>
                                    <Text>d) Odamızın hizmet binasının Çalışma Ortamının Fiziksel Yapısı (havalandırma, düzen/tertip, ferahlık vb.) hakkındaki görüşünüz?</Text>
                                    <RadioGroup
                                        value={formData.physicalStructure}
                                        onChange={(value) =>
                                            handleInputChange({ target: { name: 'physicalStructure', value } })
                                        }
                                    >
                                        <Stack direction="row">
                                            <Radio value="Zayıf">Zayıf</Radio>
                                            <Radio value="Orta">Orta</Radio>
                                            <Radio value="İyi">İyi</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Box>

                               
                                <Box>
                                    <Text>e) Gerektiğinde Üst Yönetime Ulaşabilme konusundaki görüşünüz?</Text>
                                    <RadioGroup
                                        value={formData.managementAccess}
                                        onChange={(value) =>
                                            handleInputChange({ target: { name: 'managementAccess', value } })
                                        }
                                    >
                                        <Stack direction="row">
                                            <Radio value="Zayıf">Zayıf</Radio>
                                            <Radio value="Orta">Orta</Radio>
                                            <Radio value="İyi">İyi</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Box>

                              
                                <Box>
                                    <Text>f) Üyesi olduğunuz Meslek Komitesinin çalışmaları hakkındaki görüşünüz?</Text>
                                    <RadioGroup
                                        value={formData.committeeWork}
                                        onChange={(value) =>
                                            handleInputChange({ target: { name: 'committeeWork', value } })
                                        }
                                    >
                                        <Stack direction="row">
                                            <Radio value="Zayıf">Zayıf</Radio>
                                            <Radio value="Orta">Orta</Radio>
                                            <Radio value="İyi">İyi</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Box>
                            </VStack>
                        </Box>

                        <Text>Varsa önerileriniz:</Text>
                        {formData.additionalFeedback.map((feedback, index) => (
                            <Textarea
                                key={index}
                                placeholder={`Öneri ${index + 1}`}
                                value={feedback}
                                onChange={(e) => handleArrayInputChange(index, e.target.value)}
                            />
                        ))}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                        Gönder
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Kapat
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
