import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
export default function Admin() {

  return (
    <>
      <div className="container">
        <Helmet>
          <title>Yönetici Sayfası</title>
          <meta name="description" content="Yönetici Sayfası" />
          <meta name="keywords" content="Yönetici Sayfası" />
        </Helmet>
        <h1>Yönetici Sayfası</h1>
        <div className="row">
          
            <ul className='adminContainer'>
              <li>
                <Link to='/duyuruEkle'>
                  <div className="card" style={{width: '18rem'}}>
                    <img src="https://e-tugra.com.tr/wp-content/uploads/2022/01/duyuru-1.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <p className="card-text">Duyuru Ekle</p>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to='/ithaltciFirmaEkle'>

                  <div className="card" style={{width: '18rem'}}>
                    <img src="https://www.pngmart.com/files/21/Add-Button-PNG-Clipart.png" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <p className="card-text">İthalatçı Firma Ekle</p>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to='/haberler'>


                  <div className="card" style={{width: '18rem'}}>
                    <img src="https://cdn-icons-png.flaticon.com/512/1159/1159633.png" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <p className="card-text"> Duyuru-Haber Düzenle</p>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to='/iletisimFormlari'>


                  <div className="card" style={{width: '18rem'}}>
                    <img src="https://static.vecteezy.com/ti/vetor-gratis/p1/3520486-mail-icon-simple-outline-mail-vector-icon-on-white-background-vetor.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <p className="card-text"> Gelen İletişim Formları</p>
                    </div>
                  </div>
                </Link>
              </li>

              <li>
                <Link to='/anketSonuc'>


                  <div className="card" style={{width: '18rem'}}>
                    <img src="https://static.vecteezy.com/ti/vetor-gratis/p1/3520486-mail-icon-simple-outline-mail-vector-icon-on-white-background-vetor.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <p className="card-text">Anket Sonuçları</p>
                    </div>
                  </div>
                </Link>
              </li>
             
            </ul>
         
        </div>
      </div>
    </>
  )
}
