import React,{useState,useEffect} from 'react'
import Header from './components/header'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer'
import './style/style.css';
import PrivateRoute from './components/PrivateRoute';
import { jwtDecode } from 'jwt-decode';

// Ana Route'lar
import Iletisim from './components/pages/iletisim';
import KaliteVeAkreditasyon from './components/pages/kaliteVeAkreditasyon';
import Home from './components/pages/home'

//Hakkimizda Dropdown Route'lar
import EskiBaskanlarimiz from './components/hakkimizdaDropdown/eskiBaskanlarimiz';
import KalitePolitikasi from './components/hakkimizdaDropdown/kalitePolitikasi';
import KurumOrganlari from './components/hakkimizdaDropdown/kurumOrganlari';
import KurumsalLogo from './components/hakkimizdaDropdown/kurumsalLogo';
import MisyonVizyon from './components/hakkimizdaDropdown/misyonVisyon';
import Tarihcemiz from './components/hakkimizdaDropdown/tarihcemiz';

//Kurum Organlari Dropdows Route'lar
import GenelSekreterlik from './components/kurumOrganlari/genelSekreterlik'
import MeclisKomitesiUyeleri from './components/kurumOrganlari/meclisKomitesiUyeleri'
import MeclisUyeleri from './components/kurumOrganlari/meclisUyeleri'
import OrganizasyonSeması from './components/kurumOrganlari/organizasyonSemasi'
import YonetimKuruluUyeleri from './components/kurumOrganlari/yonetimKuruluUyeleri'



//Hizmetler Dropdown Route'lar
import AbKalite from './components/hizmetlerDropdown/abKaliteAkreditasyon';
import ArgeBirimi from './components/hizmetlerDropdown/argeBirimi';
import BasinYayinDepartmani from './components/hizmetlerDropdown/basinYayinDepartmani';
import BilgiIslemHizmetleri from './components/hizmetlerDropdown/bilgiIslemHizmetleri';
import DeikBirimi from './components/hizmetlerDropdown/deikBirimi';
import EgitimOrganizasyonBirimi from './components/hizmetlerDropdown/egitimOrganizasyonBirimi';
import EkspertizRaporu from './components/hizmetlerDropdown/ekspertizRaporu';
import HalklaIliskilerBirimi from './components/hizmetlerDropdown/halklaIliskilerBirimi';
import IgemeBirimi from './components/hizmetlerDropdown/igemeBirimi';
import InsanKaynakları from './components/hizmetlerDropdown/insanKaynakları';
import IsMakinesiIslemleri from './components/hizmetlerDropdown/isMakinesiIslemleri';
import KBelgeYetkisi from './components/hizmetlerDropdown/kBelgeYetkisi';
import KapasiteRaporu from './components/hizmetlerDropdown/kapasiteRaporu';
import MevzuatKanunlar from './components/hizmetlerDropdown/mevzuatKanunlar';
import MuhasebeFinansBirimi from './components/hizmetlerDropdown/muhasebeFinansBirimi';
import OdaSicilMemurlugu from './components/hizmetlerDropdown/odaSicilMemurlugu';
import SigortaKayıtBirimi from './components/hizmetlerDropdown/sigortaKayıtBirimi';
import TicaretSicilMudurlugu from './components/hizmetlerDropdown/ticaretSicilMudurlugu';
import TicariVizeHizmetleri from './components/hizmetlerDropdown/ticariVizeHizmetleri';
import TpadkBirimi from './components/hizmetlerDropdown/tpadkBirimi';
import TseTemsilcilik from './components/hizmetlerDropdown/tseTemsilcilik';
import YatirimDanısmanlıgı from './components/hizmetlerDropdown/yatirimDanısmanlıgı';
import YaziIsleri from './components/hizmetlerDropdown/yaziIsleri';


//Bilgi Bankası Route'ları
import Basin from './components/bilgiBankasiDropdown/basin';
import FaaliyetRaporu from './components/bilgiBankasiDropdown/faaliyetRaporu';
import Haberler from './components/bilgiBankasiDropdown/haberler';
import IthalatcıFirma from './components/bilgiBankasiDropdown/ithalatciFirmalar';
import RaporYayin from './components/bilgiBankasiDropdown/raporYayin';
import UlkeRaporlari from './components/bilgiBankasiDropdown/ulkeRaporlari';
import VideoGaleri from './components/bilgiBankasiDropdown/videoGaleri';

//Ardahan Route'ları
import Ekonomi from './components/ardahanDropdown/ekonomi';
import GenelBilgiler from './components/ardahanDropdown/genelBilgiler';
import KulturVeTurizm from './components/ardahanDropdown/kulturVeTurizm';
import ResimGalerisi from './components/ardahanDropdown/resimGalerisi';
import Tarihce from './components/ardahanDropdown/tarihce';
import Urunler from './components/ardahanDropdown/urunler';


import TarimsalUrunler from './components/ardahanDropdown/tarimsalUrunler';
import HayvansalUrunler from './components/ardahanDropdown/hayvansalUrunler';
import DogalUrunler from './components/ardahanDropdown/dogalUrunler';


//Yönetici Route'ları
import Admin from './components/adminPages/admin';
import Login from './components/adminPages/login';
import Register from './components/adminPages/register';

//Yönetici İşlem Routeları
import DuyuruEkle from './components/adminPages/duyuruEkle';
import HaberEkle from './components/adminPages/haberEkle';
import Duyurular from './components/pages/duyurular';
import DuyuruDuzenle from './components/adminPages/duyuruDuzenle';
import IthaltciFirmaEkle from './components/adminPages/ithaltciFirmaEkle';
import GelenIletisimFormlari from './components/adminPages/gelenIletisimFormlari';


//Footer Routeları
import KullanimSartlari from './components/baglantilar/kullanimSartlari';
import GizlilikSozlesmesi from './components/baglantilar/gizlilikSozlesmesi';


//------Kariyer---------//
import IseAlimMain from './components/iseAlim/IseAlimMain';
//Employer//
import Employer from './components/employer/Employer';
import EmployerRegister from './components/employer/EmployerRegister';
import AddJobPost from './components/employer/AddJobPost';
import EditJobPost from './components/employer/EditJobPost';
//User//
import User from './components/user/User';
import UserRegister from './components/user/UserRegister';
//Jobs//
import EmployerJobs from './components/employer/EmployerJobs';
import AllJobs from './components/employer/AllJobs';
import IsIlanlari from './components/iseAlim/IsIlanlari';
import UserApplication from './components/user/UserApplication';
import JobDetail from './components/employer/JobDetail';

//Dilekce
import Dilekce from './components/Dilekce/Dilekce';

//Is Yerleri
import IsYerleri from './components/Is Yerleri/IsYerleri';

//Anket
import SurveyModal from './components/Anket/SurveyModal';
import AnketSonuc from './components/adminPages/AnketSonuc';

export default function App() {

  const isAuthenticated = !!localStorage.getItem('token');
  const [role, setRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken.role);
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setIsModalOpen(true); 
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
 
   
  return (
    <div>
    
      <Header></Header>
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route element={<Home/>} path="/" />
            <Route element={<KaliteVeAkreditasyon />} path="/kaliteVeAkreditasyon" />
            <Route element={<Iletisim />} path="/iletisim" />
            <Route element={<EskiBaskanlarimiz />} path="/eskiBaşkanlarimiz" />
            <Route element={<KalitePolitikasi />} path="/kalitePolitikasi" />
            <Route element={<KurumOrganlari />} path="/kurumOrganlari" />
            <Route element={<KurumsalLogo />} path="/kurumsalLogo" />
            <Route element={<MisyonVizyon />} path="/misyonVisyon" />
            <Route element={<Tarihcemiz />} path="/tarihcemiz" />

            <Route element={<GenelSekreterlik />} path="/genelSekreterlik" />
            <Route element={<MeclisKomitesiUyeleri />} path="/meclisKomitesiUyeleri" />
            <Route element={<MeclisUyeleri />} path="/meclisUyeleri" />
            <Route element={<OrganizasyonSeması />} path="/organizasyonSemasi" />
            <Route element={<YonetimKuruluUyeleri />} path="/yonetimKuruluUyeleri" />
            
            <Route element={<AbKalite />} path="/abKaliteAkreditasyon" />
            <Route element={<ArgeBirimi />} path="/argeBirimi" />
            <Route element={<BasinYayinDepartmani />} path="/basinYayinDepartmani" />
            <Route element={<BilgiIslemHizmetleri />} path="/bilgiIslemHizmetleri" />
            <Route element={<DeikBirimi />} path="/deikBirimi" />
            <Route element={<EgitimOrganizasyonBirimi />} path="/egitimOrganizasyonBirimi" />
            <Route element={<EkspertizRaporu />} path="/ekspertizRaporu" />
            <Route element={<HalklaIliskilerBirimi />} path="/halklaIliskilerBirimi" />
            <Route element={<IgemeBirimi />} path="/igemeBirimi" />
            <Route element={<InsanKaynakları />} path="/insanKaynaklari" />
            <Route element={<IsMakinesiIslemleri />} path="/isMakinesiIslemleri" />
            <Route element={<KBelgeYetkisi />} path="/kBelgeYetkisi" />
            <Route element={<KapasiteRaporu/>} path="/kapasiteRaporu" />
            <Route element={<MevzuatKanunlar/>} path="/mevzuatKanunlar" />
            <Route element={<MuhasebeFinansBirimi/>} path="/muhasebeFinansBirimi" />
            <Route element={<OdaSicilMemurlugu/>} path = "/odaSicilMemurlugu" />
            <Route element={<SigortaKayıtBirimi/>} path="/sigortaKayıtBirimi" />
            <Route element={<TicaretSicilMudurlugu/>} path = "/ticaretSicilMudurlugu" />
            <Route element={<TicariVizeHizmetleri/>} path="/ticariVizeHizmetleri" />
            <Route element={<TpadkBirimi/>} path = "/tpadkBirimi" />
            <Route element={<TseTemsilcilik/>} path="/tseTemsilcilik" />
            <Route element={<YatirimDanısmanlıgı/>} path = "/yatirimDanısmanlıgı" />
            <Route element={<YaziIsleri/>} path = "/yaziIsleri" />
            <Route element={<Basin/>} path = "/basin" />
            <Route element={<FaaliyetRaporu/>} path = "/faaliyetRaporu" />
            <Route element={<Haberler/>} path = "/haberler" />
            <Route element={<IthalatcıFirma/>} path = "/ithalatciFirmalar" />
            <Route element={<RaporYayin/>} path = "/raporYayin" />
            <Route element={<UlkeRaporlari/>} path = "/ulkeRaporlari" />
            <Route element={<VideoGaleri/>} path = "/videoGaleri" />
            <Route element={<Ekonomi/>} path = "/ekonomi" />
            <Route element={<GenelBilgiler/>} path = "/genelBilgiler" />
            <Route element={<KulturVeTurizm/>} path = "/kulturVeTurizm" />
            <Route element={<ResimGalerisi/>} path = "/resimGalerisi" />
            <Route element={<Tarihce/>} path = "/tarihce" />
            <Route element={<Urunler/>} path = "/urunler" />

            <Route element={<TarimsalUrunler/>} path = "/tarimsalUrunler" />
            <Route element={<HayvansalUrunler/>} path = "/hayvansalUrunler" />
            <Route element={<DogalUrunler/>} path = "/dogalUrunler" />

            <Route path="/admin" element={<PrivateRoute element={<Admin />} isAuthenticated={isAuthenticated} isAdmin={role === 'admin'} />} />
            <Route path="/anketSonuc" element={<PrivateRoute element={<AnketSonuc />} isAuthenticated={isAuthenticated} isAdmin={role === 'admin'} />} />


            <Route element={<Login/>} path = "/login" />
            <Route element={<Register/>} path = "/register" />
            <Route element={<DuyuruEkle/>} path = "/duyuruEkle" />
            <Route element={<HaberEkle/>} path = "/haberEkle" />
            <Route element={<Duyurular/>} path = "/announcementDetail/:id" />
            <Route element={<DuyuruDuzenle/>} path = "/announcementEdit/:id" />
            <Route element={<IthaltciFirmaEkle/>} path = "/ithaltciFirmaEkle" />
            <Route element={<GelenIletisimFormlari/>} path = "/iletisimFormlari" />
            <Route element={<KullanimSartlari/>} path = "/kullanimSartlari" />
            <Route element={<GizlilikSozlesmesi/>} path = "/gizlilikSozlesmesi" />
            <Route element={<IseAlimMain/>} path = "/ardahanIs" />
            <Route element={<IsIlanlari/>} path = "/isIlanlari" />
            <Route element={<UserRegister/>} path = "/kariyerUyelik" />
            <Route element={<User/>} path = "/kullanici" />
            <Route element={<UserApplication/>} path = "/basvurularim" />
            <Route element={<Employer/>} path = "/isveren" />
            <Route element={<EmployerRegister/>} path = "/isverenKayit" />
            <Route element={<AddJobPost/>} path = "/isEkle" />
            <Route element={<EditJobPost/>} path = "//isDuzenle/:id" />
            <Route element={<EmployerJobs/>} path = "/isIlanlarim" />
            <Route element={<AllJobs/>} path = "/tumIslanlarım" />
            <Route element={<JobDetail/>} path = "/isDetaylari" />
            <Route element={<Dilekce/>} path = "/tsicil_dilekce" />
            <Route element={<IsYerleri/>} path = "/anlasmaliIsYerleri" />



          </Routes >
        </div >
        <Footer/>
      </Router >
      <SurveyModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div >
  )
}